.item {
    /* background-color: #c5e3fe; */
    max-width: max-content;
    text-align: center;
    margin: 64px;
}

.item img {
    width: 400px;
}

.title {
    text-align: left;
    padding-bottom: 24px;
    padding-top: 24px;
    padding-left: 12px;
    box-shadow: 0px 1px 1px 0px rgba(126, 126, 126, 0.10);
    border-top: 1px solid  rgba(126, 126, 126, 0.10);
}

.hover-image {
    height: auto;
    transition: 0.3s ease-in-out;
  }

@media (max-width: 600px) {

    .item {
        max-width: max-content;
        display: inline-flex;
        margin-bottom: 5px;
        padding: 100px 50px 100px 50px;
        /* box-shadow: 0px 1px 1px 0px rgba(126, 126, 126, 0.10); */
        flex-wrap: wrap;
        margin: 0px;
    }

    .item img {
        width: 100%;
    }
}



@media (min-width: 601px) and (max-width: 1024px) {

    .items {
        flex-wrap: nowrap;
        width: 100%;
        display: flex;
        align-items: start;
    }

    .item {
        max-width: max-content;
        text-align: center;
        margin: 0px;
    }

    .item img {
        width: 100%;
    }

    .shop-item-img {
        padding: 20px;
    }
}


@media (min-width: 1025px) {
    .items {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .item {
        max-width: max-content;
        text-align: center;
    }

    .item img {
        width: 100%;
    }

    .shop-item-img {
        padding: 20px;
    }

}