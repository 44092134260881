/* Carousel.css */

.carouselslide-container {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
  }
  

.slick-slide img:focus {
  outline: none;
}

.slick-prev:before, .slick-next:before {
  color: #c5e3fe; /* Customize arrow color */
}

.slick-dots li button:before {
  color: #c5e3fe; /* Customize dot color */
}

.slick-slide img {
  width: 100%;
  border-radius: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}


@media (max-width: 600px) {
  .carouselslide-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  }
}


@media (min-width: 601px) and (max-width: 1024px) {

}


@media (min-width: 1025px) {

}


  