

@keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
    from {background-position: 0;}
    to {background-position:10000px 0;}
}

.clouds {
  /* position: absolute; */
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  z-index: -1;
}


.clouds{
    background:transparent url('../assets/clouds.svg') repeat top center;
    z-index:3;
    background-size: cover;

  -moz-animation:move-clouds-back 200s linear infinite;
  -ms-animation:move-clouds-back 200s linear infinite;
  -o-animation:move-clouds-back 200s linear infinite;
  -webkit-animation:move-clouds-back 200s linear infinite;
  animation:move-clouds-back 200s linear infinite;
}


.mountains {
    /* position: absolute; */
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    z-index: -1;
  }
  
  
  .mountains{
      background:transparent url('../assets/clouds.webp') repeat top center;
      z-index:3;
      background-size: cover;
  
    -moz-animation:move-clouds-back 200s linear infinite;
    -ms-animation:move-clouds-back 200s linear infinite;
    -o-animation:move-clouds-back 200s linear infinite;
    -webkit-animation:move-clouds-back 200s linear infinite;
    animation:move-clouds-back 200s linear infinite;
  }