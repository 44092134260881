.masonry-grid {
    display: flex;
    justify-content: center; /* Center the columns */
    margin-left: -16px; /* Adjust the gutter size as needed */
    width: auto;
    align-items: stretch;
}
  
.masonry-grid_column {
  padding-left: 16px; /* Adjust the gutter size as needed */
  background-clip: padding-box;
}

/* Style for individual items */
.masonry-grid_column > div {
  margin-bottom: 16px; /* Adjust the spacing between items as needed */
  overflow: hidden; /* Ensures that overflowing content is hidden */
  border-radius: 8px; /* Rounds the corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow effect */
  transition: transform 0.3s ease; /* Smooth transition for transform */
  display: flex;
  justify-content: center; /* Center the columns */
}

.masonry-grid_column > div img {
  width: 90%;
  height: auto;
  display: block;
  border-radius: 8px; /* Ensures images have rounded corners */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect */
.masonry-grid_column > div:hover {
  transform: translateY(-10px); /* Moves the item up slightly on hover */
}

/* Responsive adjustments */
@media (max-width: 1100px) {
  .masonry-grid {
    margin-left: -12px;
  }

  .masonry-grid_column {
    padding-left: 12px;
  }

  .masonry-grid_column > div {
    margin-bottom: 12px;
  }
}
  
@media (max-width: 700px) {
  .masonry-grid {
    margin-left: -8px;
  }

  .masonry-grid_column {
    padding-left: 8px;
  }

  .masonry-grid_column > div {
    margin-bottom: 8px;
  }
}

@media (max-width: 500px) {
  .masonry-grid {
    margin-left: -4px;
  }

  .masonry-grid_column {
    padding-left: 4px;
  }

  .masonry-grid_column > div {
    margin-bottom: 4px;
  }
}