.blog-main-container {
    text-align: left;
    padding: 50px 100px 100px 0px;
    padding-left: 25vh;
}

.blog-img img {
    width: 40%;
}

.blog-container {
    box-shadow: 0px 1px 0px 0px  #c5e3fe;
}

.blog-img-container img{
    width: 100%;
}

.blog-img-container {
    padding: 16px;
}



/* Small screens (phones) */
@media (max-width: 600px) {
.blog-main-container {
    padding-left: 24px;

}

#title {
    margin-bottom: 50px;
}

.blog-container {
    padding: 10px 10px 10px 10px;
    margin: 0px -50px 0px 0px;
}

.blog-img img {
    width: 100%;
}

}


@media (min-width: 601px) and (max-width: 1024px) {
    .blog-main-container {
        padding-left: 24px;

    }

    #title {
        margin-bottom: 50px;
    }

    .blog-container {
        padding: 10px 10px 10px 10px;
        margin: 0px -50px 0px 0px;
    }
}
  
  
@media (min-width: 1025px) {
    #title {
        margin-bottom: 50px;
    }
    
    .blog-container {
        padding: 10px 50px 10px 10px;
        margin: 0px 20vh 0px 0px;
    }

    .blog-img-container img{
        display: none;
    }



}  