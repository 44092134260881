

.open-effect h2 {
    margin: 0; padding: 0;
    position: absolute;
    top: 23vh; left: 50%;
    transform:translate(-50%,-50%);
    font-size: 14vh; color:transparent;
    text-transform:uppercase;
  }
  .open-effect h2 span:nth-child(1){
    position:absolute;
    top:0; left:0; color: #c5e3fe;
    transition:0.5s;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    overflow:hidden;
  }
  .open-effect h2:hover span:nth-child(1){
    transform:translateY(-18px); 
  }
  .open-effect h2 span:nth-child(2){
    position:absolute;
    top:0; left:0; color: #c5e3fe;
    transition:0.5s;
    clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
    overflow:hidden;
  }
  .open-effect h2:hover span:nth-child(2){
    transform:translateY(18px); 
  }
  .open-effect h2 span:nth-child(3){
    position:absolute;
    top:50%; left: 0;
    transform:translateY(-50%) scaleY(0);
    width: 91%; color:#c5e3fe;
    background:  #070F19;;
    font-size: 0.25em; font-weight: 500;
    letter-spacing:0.7em;
    text-align:center;
    padding-left: 20px; margin-left: 5px;
    transition:0.5s;
  }
  .open-effect h2:hover span:nth-child(3){
    transform:translateY(-50%) scaleY(1);
  }


#about-logo {
    width: 200px;
    margin-right: 44px;
}

.about-description-container {
    text-align: left;
    margin-top: 64px;
    line-height: 2;
    margin-left: 24px;
    margin-right: 24px;
}

.about-main-container {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 24px;

}

.flourescent-about {
    height: 25px;
    flex-shrink: 0;
    background: linear-gradient(90deg, #5D95A4 0%, #C97838 100%);
}

.statement-container {
    text-align: left; 
    line-height: 2;
    margin-bottom: 100px;
}

/* Small screens (phones) */
@media (max-width: 600px) {
.about-container {
    text-align: left;
    margin-left: 24px;
    margin-right: 50px;
    line-height: 2;
    margin-bottom: 24px;
}




.about-main-container {
  margin-left: 24px;
  margin-right: 24px;
}


.about-description-container {
    margin-left: 12px;
    margin-right: 24px;
    /* margin-top: 128px; */
}

.statement-container {
    margin-left: 24px;
    margin-right: 24px;
}

#about-logo {
    width: 150px;
    margin-right: 0px;
    margin-top: 20px;
}

.open-effect h2 {
   
    font-size: 5em; color:transparent;
  }

}