@font-face {
    font-family: Ancient Medium;
    src: url('../fonts/Ancient Medium.ttf');
  }


.nowPlayingCard {
    flex-shrink: 0;
    border-radius: 22px;
    /* border: 2px solid   #C5E3FE ;
    box-shadow: 5px 5px 0   #C5E3FE ; */
    align-items: center;
    /* padding: 24px; */
    width: 50vh;
    height: min-content;
    display: inline-block;
    justify-content: space-between;
    transition: all 0.5s ease;
    color: #c5e3fe;
    overflow: hidden;
    white-space: wrap;
    margin-top: 20vh;
    font-weight: 600;
}



  
.nowPlayingCard a {
    color:  #C5E3FE ;
    text-decoration: none;
}

.nowPlayingCard a:hover {
    color:   #C5E3FE ;
    text-decoration: underline;
}

.nowPlayingImage img:hover {
     border: 2px solid   #C5E3FE ;
    box-shadow: 5px 5px 0   #C5E3FE ;
}

.nowPlayingImage img {
   border-style: solid;
   border-color:  #C5E3FE ;
    width: 40vh;
    height: 40vh;
    flex-shrink: 0;
    /* margin: 10px; */
    border-radius: 8px;
    transition: all 0.5s ease;

    
}

.eyes-offline {
    border-radius: 8px;
    border: 1px solid   #C5E3FE ;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    margin: 10px;
}

#nowPlayingDetails {
    justify-content: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 54%;
    display: flex;
    flex-direction: column;
    width: 54%;
    margin: 24px;
    margin-top: 24px;
    white-space: wrap;

    
}

.nowPlayingTitle, .playlistName {
    flex-shrink: 0;
    color:   #C5E3FE ;
    white-space: wrap;
    text-align: center;
    text-transform: lowercase;
    font-size: 32px;
    width: 100%;
    font-family: Ancient Medium !important;
    margin-bottom: 12px;
    letter-spacing: 3px;
    font-weight: 500;

}

.nowPlayingTitle, .playlistName::after {
  content: "";
  display: inline-block;
  width: 100%; 
  /* animation: scroll-right 10s linear infinite;  */
  animation-delay: 3s;
  margin-top: 24px;

}

@keyframes scroll-right {
  0% {
    transform: translateX(0%);  /* Start from the right */
  }

  33% {
    transform: translateX(-100%);  /* Move to the left */
  }
  

  66% {
    transform: translateX(0%);  /* Move to the left */
  }

  100% {
    transform: translateX(0%);  /* Move to the left */
  }
  
}


.nowPlayingArtist, .playlistHeader {
    text-align: center;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    font-family: Playfair Display;
    font-size: 20px;
    white-space: wrap;

    
}

.nowPlayingTime {
    text-align: center;
    color:   #C5E3FE ;
    font-family: Playfair Display;
    font-size: 16px;
}

.nowPlayingState {
    text-align: center;
    width: 100%;
    padding-top: 10px;
    color:    #C5E3FE ;
}
.soundbar {
    width: 25% !important;
    margin-left: -16px;
}



.nowPlayingState img {
    width: 100%;
}

.pause {
    width: 64px !important;
   
}

.spotify-container {
    display: flex;
    text-align: center;
    justify-content: center;
}
 
 
 @media (max-width: 600px) {


    .soundbar {
        width: 25% !important;
    }

    .nowPlayingImage img {
        border-radius: 8px;
        width: 32vh;
        height: 32vh;
        flex-shrink: 0;
        margin-bottom: 0px;
    }
    
    .eyes-offline {
        border-radius: 8px;
        border: 1px solid  #C5E3FE ;
        box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
        transition: all 0.5s ease;
        width: 180px;
        height: 180px;
        flex-shrink: 0;
        margin: 10px;
        margin-left: 24px;
    }

    .nowPlayingTitle, .playlistName {
    flex-shrink: 0;
    color:  #C5E3FE ;
    white-space: wrap;
    text-align: center;
    text-transform: lowercase;
    font-size: 32px;
    width: 100%;
    font-family: Ancient Medium !important;
    margin-bottom: 12px;
    line-height: 1;
    margin-top: 24px;
    font-weight: 500;


}

#nowPlayingDetails {
    justify-content: center;
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    width: 54%;
    margin: 24px;
}
     
 }  
 
 
 
  
 
 
  
 
 