#bodyshot {
    width: 100%;
    z-index: 0;
}

#christ-satan {
    
    height: 60vh;
    margin-top: -10vh;
    position: relative;
}

.crop-container {
    width: 100%;
    height: 75%;
    overflow: hidden;
    margin-left: 0vh;
    text-align: center;
    align-content: center;

}

.crop-container img {
    width: auto; /* Image is larger than the container */
    height: auto; /* Maintain aspect ratio */
}

.section #bodyshot {
    width: 70vh;
}


.bodyshot {
    margin-left: 4%;
    margin-top: 4%;
    text-align: left;
    display: flex;
    width: 100%;
}

.gray-container {
    background-color: #212121;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 4%;
    padding-top: 2%;
}

.left-container {
    float: left;
    width: 50%;
}

.right-container {
    float: right;
    width: 50%;
}

#trinityla {
    font-family: Ancient Medium;
    font-size: 128px;
    font-weight: 500;
    color: #c5e3fe;
    margin-top: -100px;
    position: relative;
    z-index: 1;
}

#neo, #evoking-divinity {
    font-family: Ancient Medium;
    font-size: 40px;
    font-weight: 500;
    color: #c5e3fe;
    margin-top: -100px;
    position: relative;
    z-index: 1;
}

#evoking-divinity {
    margin-top: 10vh;
    position: relative;
    font-size: 64px;
    margin-left: 0px;
}



#mission {
    font-family: Ancient Medium;
    font-size: 16px;
    font-weight: 500;
    color: #9C9C9C;
    position: relative;
    z-index: 1;
    width: 80%;
    line-height: 1.5;
    margin-top: -24px;
}



/* Small screens (phones) */
@media (max-width: 600px) {
    .bodyshot {
        display: block;
        margin-left: 24px;
        margin-right: 24px;
        text-align: center;
        margin-top: 24px;
      
    }
    .left-container {
        width: 80%;
        display: block;
        margin-left: 0px;
        text-align: left;
    }

    .right-container {
        width: 100%;
        text-align: left;
        
    }

    .section #bodyshot {
        width: 100%;
        margin-left: 12px;
       
    }


    #trinityla {
       
        font-size: 64px;
        font-weight: 500;
        color: #c5e3fe;
        margin-top: -60px;
        position: relative;
        z-index: 1;
        margin-left: 12px;
    }

    #neo, #evoking-divinity {
        font-family: Ancient Medium;
        font-size: 40px;
        font-weight: 500;
        color: #c5e3fe;
        margin-top: -50px;
        position: relative;
        z-index: 1;
        margin-right: 24px;
        margin-left: 16px;
    }

    #evoking-divinity {
        margin-top: 24px;
        position: relative;
        font-size: 32px;
    }

    #christ-satan {
        display: none;

    }

    #mission {
        font-family: Ancient Medium;
        font-size: 16px;
        font-weight: 500;
        color: #9C9C9C;
        position: relative;
        z-index: 1;
        width: 100%;
        line-height: 1.5;
        margin-top: -24px;
        margin-right: 24px;
        margin-left: 16px;
    }

    .image-container {
        width: 100%;
        text-align: center;
    }




    
 }
 
 
 @media (min-width: 601px) and (max-width: 1024px) {
    


    .left-container {
        margin-left: 0px;
    }

    #mission {
        font-family: Ancient Medium;
        font-size: 16px;
        font-weight: 500;
        color: #9C9C9C;
        position: relative;
        z-index: 1;
        width: 75%;
        line-height: 1.5;
        margin-top: -24px;
    }

    .bodyshot {
        display: block;       
    }

    .left-container {
        width: 100%;
        margin-left: 0px;
        text-align: left;
    }

    .right-container {
        width: 100%;
        text-align: left;
    }

    .section #bodyshot {
        width: auto;
        height: 400px;
        display: flex;
        
       
    }

    #trinityla {
       
        font-size: 84px;
        font-weight: 500;
        color: #c5e3fe;
        margin-top: -80px;
        position: relative;
        z-index: 1;
    }

    #neo, #evoking-divinity {
        font-family: Ancient Medium;
        font-size: 40px;
        font-weight: 500;
        color: #c5e3fe;
        margin-top: -50px;
        position: relative;
        z-index: 1;
    }

    #evoking-divinity {
        margin-top: 24px;
        position: relative;
        font-size: 48px;
        margin-left: 0px;
    }

    #christ-satan {
        display: none;
    }

    .gray-container {
        padding-bottom: 0px;
    }

    
 
     
   }
 
 
 
  
 
 
  
 
 