

#scroll-container {
    width: 100%;
    height: 3rem;
    font-family: Playfair Display;
    /* position: absolute; */
    overflow: hidden;
    white-space: nowrap;
    color: #fff;
    /* margin-top: 45vh; */
    background-color:  #070F19;
    box-shadow: 0 0 40px  #c5e3fe, 0 0 30px  #c5e3fe;
    background: linear-gradient(to bottom right, #070F19,  #070F19);


}
#scroll-container .scroll {
    /* background: #000; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    white-space: nowrap;
    animation: loop 60s linear infinite;
}

#scroll-container p {
    word-spacing: 13px;
}
.fade {
    width: 100%;
    position: absolute;
    background: linear-gradient(
        90deg,
        #070F19,
        transparent 10%,
        transparent 90%,
        #070F19
    );
    inset: 0;
    pointer-events: none;
}


@keyframes loop {
    0% {transform: translateX(50%);}
    100% {transform: translateX(-80%);}
}

.scroll-item {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    font-size: .9rem;
    padding-right: 12px;
    border-radius: 0.4rem;
    white-space: nowrap;
    color: #c5e3fe;
}


@media (max-width: 600px) {
    @keyframes loop {
        0% {transform: translateX(20%);}
        100% {transform: translateX(-100%);}
    }
  
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    @keyframes loop {
        0% {transform: translateX(30%);}
        100% {transform: translateX(-100%);}
    }
  
}

