body {
    /* overflow: hidden; */
}

.loading-screen {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #070F19;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1; /* Initially hidden */
    transition: opacity 2s ease-out;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: hidden;

}

.App-logo {
    animation: fadeInAnimation ease 1s forwards; /* Fade in animation */
    transition: opacity 3s ease-out;
    width: 7%;
    
}


/* Fade in animation */
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Fade out animation */
@keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: fadeOutAnimation ease 1s forwards; /* Fade out animation */
    z-index: 0;
}

@media screen and (max-width:768px) {
    .App-logo {
        width: 33%;
    }
  }

  @media (max-width: 600px) {
    .App-logo {
        width: 33%;
        margin-top: 0%;
    }
  }
  
  
  @media (min-width: 601px) and (max-width: 1024px) {
    .App-logo {
        width: 20%;
        margin-top: 0%;
    }  
  
  }
  
  @media (min-width: 1025px) {
    .App-logo {
        width: 14%;
        margin-top: 0%;
    }  
  
  }