.hamburger-container {
  cursor: pointer;
  position: relative;
  right: 0;
  top: 0;
  margin-top: 28px;
  margin-right: 12px;
}
  
.hamburger {
  list-style: none;
  padding: 0;
  margin: 0;
}

#menu {
  z-index: 1000;
  width: 100%;
  height: 100vh;
  margin-left: -32px;
  display: flex;
  justify-content: left;
  flex-direction: row-reverse;
  align-content: start;
  align-items: left;
  flex-wrap: wrap;
  
}

.hamburger-menu {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  padding-bottom: 10px;
  left: 0;
}

.hamburger-menu li {
  width: 35px;
  height: 25px;
  margin: 48px 0;
  transition: 0.4s;
  display: flex;
  font-size: 32px;
  justify-content: left;
  margin-left: 32px;
}


.hamburger li {
  width: 40px;
  height: 2px;
  background-color: #c5e3fe;
  margin: 7px 0;
  border-radius: 10px;
  transition: 0.4s;
}

.hidden {
  opacity: 0;
}

@media (min-width: 1025px) {
  .hamburger-container {
  
    display: none;
  }
}
