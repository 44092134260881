

.trinity-container {
    text-align: center;
    width: 100%;
    box-shadow: 0px 1px 1px 0px rgba(126, 126, 126, 0.10);
    
}


.landing-page {
    font-size: 1.5rem;
    margin-top: 15vh;

}

.cross {
    position: relative;
    top: 24px;
    align-self: center;
    right: 0;
    width: auto;
    z-index: 0;
}


.question {
    font-weight: 500;
    margin-top: 5vh;
    line-height: 1.2;
}



.spinning-logo {
    animation: spin 7s infinite linear;
    transform-style: preserve-3d;
    padding-top: 50px;
}



@keyframes spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
}

.home-container {
    text-align: center;
    z-index: 1;
}

#home {
    margin-top: -33px;
}

.hero-container {
    box-shadow: 0px 1px 1px 0px rgba(126, 126, 126, 0.10);
    padding-bottom: 16px;
}

#recent-work-title {
    margin-top: 24px;
    font-size: 16px;
}

.spaced {
    letter-spacing: 14px;
    margin-bottom: 7px;
}

.brand-name {
    width: 100%;
}

.distortion-container {
    /* border-radius: 100%; */
    width: 100%;
}


.slogan h1{
    color: #c5e3fe !important;
    font-weight: 400;
}

.slogan b {
    font-weight: 900;
}

.flourescent {
    height: 25px;
    flex-shrink: 0;
    background: linear-gradient(90deg, #5D95A4 0%, #C97838 100%);
}

.home {
    padding: 32px
}


.container {
    padding-top: 32px; 
}

.blue-container {
    display: flex;
    justify-content: space-between;
}

.blue-container h1 {
    color: #c5e3fe;
    font-family: Ancient Medium;
    font-size: 20vh;
    line-height: .9;
}

.rainbow {
    transition: .3s ease-out;

}

#no-border {
    -webkit-text-stroke-width: 0px;
}

.rainbow:hover {
    color: #f3e5ab;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color:#070F19;
    

}

.rainbow.visible {
    color: #f3e5ab;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color:#070F19;

}

#black {
    background-color: #070F19;
    height: 70vh;
}

#black h1 {
    color:  #c5e3fe;
}

#landing-item {
    margin-top: 14vh;
}



/* Small screens (phones) */
@media (max-width: 600px) {
   #desktop-flour {
    display: none;
    }

   .home-title {
    padding-top: 0px;
    }

    .main-title {
        font-size: 18vw;
        margin: 64px 32px 0px 32px;
    }


    .brand-name {
        width: 98%;
    }

    .blue-container h1 {
        color: #c5e3fe;
        font-family: Ancient Medium;
        font-size: 7vh;
    }


    .landing-page {
        font-size: 1.5rem;
        height: 75vh;
        margin-top: 20vh;
    } 
    
    .question {
        margin-top: 25vh;
    }

    #Neo {
        margin-top: 32vh;
    }

    #rabbit {
        margin-top: 24vh;
    }

    
    




}


@media (min-width: 601px) and (max-width: 1024px) {


    #hero {
        width: 100%;
    }

    #mobile-flour {
        display: none;
    }

    .brand-name {
        width: 98%;
    }

    .blue-container h1 {
        color: #c5e3fe;
        font-family: Ancient Medium;
        font-size: 10vh;
    }

    #Neo {
        margin-top: 40%;
    }

    #rabbit {
        margin-top: 16vh;
    }



    
    
    
  }
  
  
@media (min-width: 1025px) {

    #mobile-flour {
        display: none;
    }

   
    #desktop-flour {
        width: 90%;
        margin-left: 5%;
        margin-bottom: 44px;
    }

    .brand-name {
        width: 50%;
    }

    .landing-page {
        font-size: 1.5rem;    
    }

    .question {
        margin-top: 13vh;
        line-height: .8;

    }

    #Neo {
        margin-top: 28vh;
    }

    #rabbit {
        margin-top: 11vh;
    }


    
}  



 


 

