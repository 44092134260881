

  .zodiac-main-container {
    padding-bottom: 10%;
    padding-top: 10%;

    text-align: center;
  }

  .zodiac-wheel-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      /* background-color: #000000; */
  
  }

  .title-container {
    padding: 5px;
  }



  .zodiac-wheel {
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: #00ff00;
    overflow: hidden;
    transform: rotate(195deg); /* Adjust rotation for better alignment */
}

.zodiac-wheel {
  cursor: pointer;
}


.slice {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  clip-path: polygon(50% 50%, 100% 0, 100% 100%, 100% 37%);
  transform-origin: 50% 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 0;
}

.slice span {
  transform: rotate(30deg); /* Adjust text rotation to counteract slice rotation */
  color: white;
  position: absolute;
  z-index: 2;
  top: 25%;
  left: 82%;
  transform: rotate(57deg);
}

.slice span img:hover {
  transform: scale(1.2);
}
.slice span img {
  width: 33px;
}

.card {
  width: 100%;
}

.desktop-card {
  width: 160%;
  margin-left: -190px;

}




.aries  { transform: rotate(360deg)   rotate(0deg);  background-color: #DE3163  ; }
.taurus  { transform: rotate(330deg)  rotate(0deg);  background-color: #EB2524; }
.gemini  { transform: rotate(300deg)  rotate(0deg);  background-color: #EA7F25; }
.cancer  { transform: rotate(270deg)  rotate(0deg);  background-color: #F6E83D; }
.leo  { transform: rotate(240deg) rotate(0deg);  background-color: #F8DE23; }
.virgo  { transform: rotate(210deg) rotate(0deg);  background-color: #B5B321; }
.libra  { transform: rotate(180deg) rotate(0deg);  background-color: #5E9C1F; }
.scorpio  { transform: rotate(150deg) rotate(0deg);  background-color: #418847; }
.sagittarius  { transform: rotate(120deg) rotate(0deg);  background-color: #0D98BA; }
.capricorn { transform: rotate(90deg) rotate(0deg);  background-color: #4d7bc2; }
.aquarius { transform: rotate(60deg) rotate(0deg);  background-color: #8F649A; }
.pisces { transform: rotate(30deg) rotate(0deg);  background-color: #A8265A; }

@media (max-width: 600px) {
  .desktop-card{
    display: none;
  }

  .card {
    width: 300px;
    margin-left: -25px;
  }

  .slice span img {
    width: 24px;
  }

  .zodiac-wheel {
    position: relative;
    width: 275px;
    height: 275px;
    border-radius: 50%;
    background-color: #00ff00;
    overflow: hidden;
}


}
@media (min-width: 601px) and (max-width: 1024px) {
  .card{
    width: 500px;
  }

  .desktop-card {
    display: none;
  }

  .zodiac-wheel {
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-color: #00ff00;
    overflow: hidden;
}

}
@media (min-width: 1025px) {
  .card {
    display: none;
  }


  .desktop-card {
    width: 160%;
    margin-left: -31%;

  }

}

