
.letter {
  font-size: 33vh;
  margin: 0 5px;
  position: relative;
  cursor: pointer;
  z-index: 1;
  margin: 0px;
  transition: color 1s ease; /* Transition for color property */

}

.letter::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #FF2400 14%, #FF7700 28%, #FFD700 42%,  #9BBA03 56%, #0D93B4 70%,  #1D00CD 84%,  #C900CD 100%);
  opacity: var(--gradient-opacity, 0);
  transition: opacity 1s ease-out;
  z-index: -1;
  color: #070F19;
  margin-top: 30px;
  margin-bottom: -10px;
  margin: -24px;

}

#question .letter{
  color: #070F19 !important; 
  font-size: 10vh;
  background-color: #0D93B4;
}

#question::before{
  color: #070F19 !important; 
  font-size: 10vh;
}

#question .letter:hover{
  -webkit-text-stroke-color: #070F19 !important; 
  color: #C5E3FE !important;
}

.letter:hover {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color:#C5E3FE;
  color:  #070F19; 
}


.letter:active {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color:#C5E3FE;
  color:  #070F19; 
}

@font-face {
  font-family: Ancient Medium;
  src: url('../fonts/Ancient Medium.ttf');
}

.letter {
  font-family: Ancient Medium;
  color: #C5E3FE;
}



/* Small screens (phones) */
@media (max-width: 600px) {
  .letter {
    font-size: 18vh;
  }
  

}


@media (min-width: 601px) and (max-width: 1024px) {
  .letter {
    font-size: 28vh;
  }
  }
  
  
@media (min-width: 1025px) {

  .letter {
    font-size: 50vh;
  }
    
}  



 


 

