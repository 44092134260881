.item-container {
    display: inline-flex;
    background-color: rgb(244, 244, 244);
}

.item-img img {
    width: 100%;
}

.arrow-container {
  display: flex;
  cursor: pointer;
}

.back-container {
  display: flex;
  cursor: pointer;
}

#item-link {
  text-decoration: none;
}

.back-container img {
  margin-right: 10px;
}

#AddToBag {
  background-color: #070F19;
  border: 1px solid  #c5e3fe;
  color:  #c5e3fe;
  font-family: Playfair Display;
}

#AddToBag:hover {
  background-color:  #c5e3fe;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
}

.arrow {
  margin-left: 7px;
}

.box {
  margin-bottom: 40px;
}

.item-img {
  overflow-y: scroll;
  display: block;
  white-space: nowrap;
  
}

.item-img img {
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: scroll;
  overflow-x: hidden;
}

.item-description {
    text-align: left;
    box-shadow: 0px 1px 0px 0px rgba(126, 126, 126, 0.10);
    
}

#size {
    display: flex;
    cursor: pointer;
}

#size p {
    margin-right: 32px;
}

#details ul li {
    list-style-type: none;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  min-width: 250px;
}
  
.styled-table thead tr {
  color: #0B2545;
  border-bottom: .5px solid #c5e3fe;
  text-align: left;
  border-top: .5px solid #c5e3fe;

}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #c5e3fe;
}

#shirt-size {
  width: 50%;
}

.shirt-size-container {
  text-align: left;
}

.buttons {
  display: flex;
  justify-content: space-between;
  color: #c5e3fe;
  /* position: sticky; */
  bottom: 0;
  padding: 12px 6px 24px 6px;
  box-shadow: 0px 1px 0px 0px rgba(126, 126, 126, 0.10);
  z-index: 0;
  
}

.btn {
  width: 100%;
  height: 50px;
  margin: 6px 6px 6px 6px;
  border-radius: 0%;
  border-width: 1px;
}

.sizes {
  display: flex;
}

.selected {
  font-weight: bold;
  text-decoration: underline;
}



@media (max-width: 600px) {

  .item-description {
    text-align: left;
    padding: 12px 12px 12px 12px;
    box-shadow: 0px 1px 0px 0px rgba(126, 126, 126, 0.10);
  }

  #shirt-size {
    width: 80%;
  }

  .shirt-size-container {
    text-align: center;
  }
}



@media (min-width: 601px) and (max-width: 1024px) {
  .item-description {
    text-align: left;
    padding: 12px 12px 12px 12px;
    box-shadow: 0px 1px 0px 0px rgba(126, 126, 126, 0.10);
  }
}


@media (min-width: 1025px) {

  .container {
    display: flex; /* Use Flexbox */
  }

  .box {
    width: 50%; /* Each box takes up 50% of the container */
    box-sizing: border-box;
    align-content: start;
    margin-left: 64px;
  }

  .item-img {
    align-content: start;
    padding-left: 50px;
    padding-right: 50px;
    height: 100vh;
    overflow: scroll;
    scrollbar-width: none;
  }

  .item-img img {
    display: block;
    overflow-y: scroll;
    overflow: hidden;
    width: 100%;
    padding-bottom: 150px;
    padding-top: 150px;
  }

  .item-description {
    text-align: left;
    padding: 12px 12px 12px 12px;
    box-shadow: 0px 1px 0px 0px rgba(126, 126, 126, 0.10);
  }

  .styled-table {
    width: 50%;
  }

  #item-description {
    padding-left: 25px;
    padding-top: 25px;
    margin-right: 25px;
  }
  
  .item-container {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    background-color: #c5e3fe;
    margin-bottom: 100px;
  }

  .buttons {
    margin-top: 20px;
  }

}