footer {
    justify-content: space-between;
    bottom: 0;
    /* margin-top: 132px; */
}

.footer-container {
    display: flex;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 8px;
    padding-top: 8px;
    align-items: center;
    align-content: center;
    /* margin-top: 16px; */
    position: relative;
    z-index: 2;
    box-shadow: 0px -1px 1px 0px rgba(126, 126, 126, 0.10);
}

.footer-container p {
    font-size: 12px;
}

.footer-container img {
    font-size: 12px;
}

@media (max-width: 600px) {
    .footer-container img {
        font-size: 8px;
    }

    footer {
        padding-bottom: 16px;

    }

    .footer-container {
        padding-bottom: 4px;
        padding-top: 16px;
        /* margin-top: 24px; */
    }

}