.blog-carousel {
    width: 50%;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  
.carousel-inner {
  display: flex;
  text-align: center;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Adjust based on number of images */
}

.carousel-container {
  padding-left: 16px;
  padding-right: 16px;
}

.flourescent {
  margin-left: 32px;
  margin-right: 32px;
}



.carousel-item {
  min-width: 100%;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  padding-top: 16px;
  text-align: center;
}

.carousel-item.active {
  opacity: 1;
}


@media (max-width: 600px) {
  .blog-carousel {
    width: 100%;   
  }
}  

@media (min-width: 601px) and (max-width: 1024px) {
  .blog-carousel {
      display: none;
  }
}

@media (min-width: 1025px) {
  .blog-carousel {
    display: none;
  }
}